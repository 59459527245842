import { useEffect, useRef, useState } from "react"
import { apiRequest, assignTemplateToAvatar, changeGender, equipAssets, equipTopOrBottomIfOutfitApplied, getAssetsByType, getMyAvatarsFromRPM, getTemplates, save } from "./ReadyPlayerMeComponents/APICalls"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setAvatarGender, setAvatarId, setPlayer, setRpmBearerToken, setRpmId } from "../../store/appUser";
import { Avatar } from "@readyplayerme/visage";
import { Template, AvatarDetails, ApiResponse, AssetType, SkinColours, allColours } from "./ReadyPlayerMeComponents/RPMTypes";
import { UserLogin } from "./ReadyPlayerMeComponents/UserLogin";
import "./ReadyPlayerMeComponents/RPMStyles.css";
import { setSelectedPlayer } from "../../store/party";
import ImageWithLoader from "./ReadyPlayerMeComponents/ImageWithLoader";
import { AssetPicker } from "./ReadyPlayerMeComponents/AssetPicker";
import TemplateSelector from "./ReadyPlayerMeComponents/TemplateSelector";
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";


type Props = {
    className: string,
    isModal: boolean,
    close?: Function,
    sendMessage?: Function
}
export const ReadyPlayerMeCustom: React.FC<Props> = ({className,isModal,close, sendMessage}) => {

    const dispatch = useAppDispatch();
    const [templates, setTemplates] = useState<Template[]>([]);
    const [assets, setAssets] = useState<AvatarDetails[]>([]);
    const [showTemplates, setShowTemplates] = useState(true);
    const [hasToken, sethasToken] = useState(false);
    const [currentAssets, setCurrentAssets] = useState("oufit");
    const [showLoader, setShowLoader] = useState("");
    const [initialCameraDistance, setInitialCameraDistance] = useState(3.2);
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
    const [openLogin, setOpenLogin] = useState(false);
    const [templatesOrAvatars, setTemplatesOrAvatars] = useState(true);
    const [showBtns, setShowBtns] = useState(false);
    const { player, rpmBearerToken, rpmId, avatarGender, avatarId, loggedIntoRPM } = useAppSelector(state => state.appUser);
    const [newName, setNewName] = useState(player.displayName);
    const playerRef = useRef(player)
    const rpmIdRef = useRef(rpmId)
    const avatarIdRef = useRef(avatarId) 

    useEffect(() => {
        if (rpmId === null && player.glbUrl !== null) {
                const createPlayer = async () => {
                    const response = await apiRequest<ApiResponse>(
                        'https://testapp-uy5uxo.readyplayer.me/api/users',
                        {
                            method: 'POST',
                            body: { data: { applicationId: '6620f37fc062d6de28094e5d' } },
                            headers: {
                                'Content-Type': 'application/json',
                                'x-api-key': 'sk_live_ukCGG9Uw2rD1mCJAlV0Yk1JDyozjAIgvTohC',
                            },
                        }
                    );
    
                    if (response.error) {
                        console.error('API Error:', response.error);
                    } else {
                        console.log('API Data:', response.data?.data);
                        dispatch(setRpmId(response.data?.data.id));
                        dispatch(setRpmBearerToken(response.data?.data.token));
                        sethasToken(true)
                        getAssets(response.data?.data.id, "outfit")
                    }
                };
    
                createPlayer();
            
        } else {
            setShowTemplates(false)
            setSelectedTemplate(player.glbUrl + `?v=${Date.now()}`)
        }
    }, [])

    useEffect(() => {
        playerRef.current = player
        avatarIdRef.current = avatarId
        if (rpmId) {
            rpmIdRef.current = rpmId
        }
    }, [player, rpmId, avatarId])

    useEffect(() => {
        if (hasToken) {
            getTemplates(rpmBearerToken).then(response  => {
                if(response)
                if (Array.isArray(response.data)) {
                    const excludedIndices = [0, 1, 8, 15, 18];
    
                    const validTemplates = response.data.filter(
                        (template, index) =>
                            template.usageType !== "onboarding" &&
                            !excludedIndices.includes(index)
                    );
                    setTemplates(validTemplates);
                } else if (response.data) {
                    setTemplates(response.data as Template[]);
                }
            });
        }
    }, [hasToken, rpmBearerToken])

    function getMyAvatars() {
        getMyAvatarsFromRPM(rpmBearerToken, rpmIdRef.current).then(response => {
            if(response){
                console.log('API Data:', response.data);
                let templates: Template[] = []
                if (response.data.length) {
                    templates = response.data.map(item => ({
                        imageUrl: `https://models.readyplayer.me/${item.id}.png`,
                        gender: item.data.gender,
                        id: item.id,
                        usageType: "myAvatars"
                    }));
                    setTemplates(templates)
                }
            }
        })
    }

    function assignTemplate(template: Template) {
        assignTemplateToAvatar(template, rpmBearerToken).then(response => {
            if(response)
            if (response.data.id)
                saveAvatar(response.data.id)
            setShowTemplates(false)
            dispatch(setAvatarGender(template.gender))
        })
    }

    useEffect(() => {
        if (rpmIdRef.current)
            getAssets(rpmIdRef.current, "outfit")
    }, [rpmIdRef.current, avatarGender])

    function saveAvatar(id: string) {
        save(id).then(response => {
            if(response){
                let stateToUpdate = {
                    ...playerRef.current,
                    glbUrl: "https://models.readyplayer.me/" + response.data.id + ".glb"
                };
                dispatch(setPlayer(stateToUpdate))
                dispatch(setAvatarId(response.data.id))
                if (rpmIdRef.current){
                    setSelectedTemplate("https://models.readyplayer.me/" + response.data.id + ".glb"  + `?v=${Date.now()}`)
                }
            }
        })
    };

    function assignMyAvatar(template: Template) {
        setShowTemplates(false)
        dispatch(setAvatarGender(template.gender))
        let stateToUpdate = {
            ...playerRef.current,
            glbUrl: template.imageUrl.replace("png", "glb")
        };
        dispatch(setPlayer(stateToUpdate))
        dispatch(setAvatarId(template.id))
        if (rpmIdRef.current)
        getAssets(rpmIdRef.current, "outfit")
        setSelectedTemplate(template.imageUrl.replace("png", "glb") + `?v=${Date.now()}`)
    }

    function getAssets(id: string | null | undefined, type: string) {
        if (id !== null || id !== undefined) {
            getAssetsByType(id as string, type, rpmBearerToken,rpmId as string).then(response => {
                if(response){

                    if (Array.isArray(response.data)) {
                        setAssets(response.data as AvatarDetails[])
                    } else if (response.data) {
                        setAssets(response.data as AvatarDetails[])
                    }
                    setCurrentAssets(type)
                }
            })
        }
    }

    function equipTopOrBottom(asset:  AssetType){
        setShowLoader("")
        equipTopOrBottomIfOutfitApplied(asset, rpmBearerToken, avatarIdRef.current)
        setSelectedPlayer("https://models.readyplayer.me/" + avatarIdRef.current + ".glb"  + `?v=${Date.now()}`)
    }

    function updateAvatar(asset: AssetType ) {
        setShowLoader("")
        equipAssets(asset, rpmBearerToken, avatarIdRef.current).then(() => {
            saveAvatar(avatarIdRef.current)
        })        
    }


    function handleAssetSelection(type: string, initialDistance: number) {
        setInitialCameraDistance(initialDistance)
        getAssets(rpmIdRef.current, type)
    }

    function toggleTemplatesMyAvatars(isTemplates: boolean) {
        if (isTemplates) {
            setTemplatesOrAvatars(false)
            getMyAvatars()
        } else {
            setTemplatesOrAvatars(true)
            getTemplates(rpmBearerToken).then(response => {
                if(response)
                if (Array.isArray(response.data)) {
                    setTemplates(response.data as Template[]);
                } else if (response.data) {
                    setTemplates(response.data as Template[]);
                }
            })
        }
    }

    function handelGenderChange(gender: "male" | "female"){
            setShowLoader("")
            changeGender({id: gender, type: 'gender'}, rpmBearerToken, avatarIdRef.current).then(() => {
            saveAvatar(avatarIdRef.current)
            dispatch(setAvatarGender("male"))
            })
    }

    function getRandomAvatar(templates: Template[], gender: string): Template  {
        const excludedIndices = [0, 1, 8, 15, 18];
    
        const validTemplates = templates.filter(
            (template, index) =>
                template.gender === gender &&
                template.usageType !== "onboarding" &&
                !excludedIndices.includes(index)
        );
        const randomIndex = Math.floor(Math.random() * validTemplates.length);
        return validTemplates[randomIndex];
    }

    function handleAvatarName(name:string){
        if(name.length > 0){
          setShowBtns(true)
        } else {
          setShowBtns(false)
        }
        let clean = cleanMessage(name)
        setNewName(clean)
      }
    
      function updateNameOnly(){
        //let nameCookie = Cookies.get("username")
        console.log("updateName Only")
        if(sendMessage != null && playerRef.current.displayName != ""){
          let data = {
            displayName: playerRef.current.displayName,
            avatarUrl: playerRef.current.glbUrl,
            avatarProfilePic: playerRef.current.glbUrl?.replace(".glb", ".png"),
            colour: playerRef.current.colour
          }
          sendMessage("ReactClientManager", "SetLocalAvatar",  JSON.stringify(data));
          let stateToUpdate = {
            ...playerRef.current,
            displayName: newName
          };
          dispatch(setPlayer(stateToUpdate))
          setShowBtns(false)
        }
        if(close){
          close(false)
        } 
    }
    

    return (
        <div style={{position: 'absolute', zIndex: 50000, height: isModal ? '90%' :'100%', width: '100%'}}>
            {
                showTemplates && (loggedIntoRPM  ? <button
                    className="rpm-button"
                    style={{position: 'absolute', margin : '20px', top: '10px'}}
                    onClick={() => toggleTemplatesMyAvatars(templatesOrAvatars)}
                >Toggle Templates/MyAvatars</button> : <button className="rpm-button" style={{position: 'absolute', margin : '20px'}} onClick={() => setOpenLogin(true)}>Login</button>)
            }
            
            {
                showTemplates && (
                    <div style={{zIndex: 60000, position: 'relative', margin: 'auto', height: '70%'}}>
                    {templates.length > 0 && showTemplates && (
                        <TemplateSelector 
                            templates={templates}
                            loggedIntoRPM={loggedIntoRPM}
                            showTemplates={showTemplates}
                            templatesOrAvatars={templatesOrAvatars}
                            assignMyAvatar={assignMyAvatar}
                            assignTemplate={assignTemplate}
                            getRandomAvatar={getRandomAvatar}
                        />
                        )}
                      
                    </div>
                )
            }
          
          
          
            <div className={className}>

                <div style={{ flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {
                        !showTemplates && selectedTemplate && (
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                              {
                                !showTemplates && selectedTemplate && (
                                  <>
                                    <Avatar
                                      animationSrc="/animations/Idle.fbx"
                                      backLightColor="#FFB878"
                                      backLightIntensity={1}
                                      background={{
                                        color: 'rgb(235, 235, 235)',
                                      }}
                                      bloom={{
                                        intensity: 1,
                                        kernelSize: 1,
                                        luminanceSmoothing: 1,
                                        luminanceThreshold: 1,
                                        materialIntensity: 1,
                                        mipmapBlur: true,
                                      }}
                                      cameraInitialDistance={initialCameraDistance}
                                      cameraTarget={1.55}
                                      dpr={2}
                                      emotion={{
                                        cheekSquintLeft: 0.3,
                                        eyeLookInRight: 0,
                                        eyeLookOutLeft: 0,
                                        jawOpen: 0.1,
                                        mouthDimpleLeft: 0.3,
                                        mouthPressLeft: 0.1,
                                        mouthSmileLeft: 0.2,
                                        mouthSmileRight: 0.1,
                                      }}
                                      environment="soft"
                                      fillLightColor="#6794FF"
                                      fillLightIntensity={3}
                                      keyLightColor="#FFFFFF"
                                      keyLightIntensity={0.8}
                                      modelSrc={selectedTemplate}
                                      scale={1}
                                      onLoaded={() => { setShowLoader("none"); }}
                                      style={{
                                        borderRadius: '15px 0px 0px 15px'
                                      }}
                                    />
                                    <div style={{ 
                                      position: 'absolute', 
                                      bottom: '0', 
                                      display: 'flex', 
                                      flexDirection: 'row', 
                                      flexWrap: 'wrap', 
                                      gap: '10px', 
                                      justifyContent: 'center', 
                                      width: '100%' 
                                    }}>
                                      {currentAssets === "outfit" 
                                        ? SkinColours.map((colour, index) => {
                                          return (
                                            <div 
                                              key={index}
                                              style={{
                                                backgroundColor: colour,
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '40px',
                                                cursor: 'pointer'
                                              }}
                                              onClick={() => {
                                                updateAvatar({
                                                  type: "skinColor",
                                                  id: index
                                                });
                                              }}
                                            ></div>
                                          );
                                        }) 
                                        : null}
                                    </div>
                                    
                                    <div
                                        style={{
                                            overflowX: 'auto', 
                                            position: 'absolute',
                                            bottom: '0px', 
                                            display: 'flex',
                                            whiteSpace: 'nowrap', 
                                            justifyContent: 'start',
                                            width: '100%',
                                            padding: '10px 0', 
                                        }}
                                        >
                                        {["hair", "beard", "eyebrows"].includes(currentAssets)
                                            ? allColours.map((colour, index) => {
                                            return (
                                                <div
                                                key={index}
                                                style={{
                                                    display: 'inline-block', 
                                                    backgroundColor: colour,
                                                    borderRadius: '50%', 
                                                    width: '40px',
                                                    height: '40px',
                                                    cursor: 'pointer',
                                                    marginRight: '10px', 
                                                    flexShrink: 0, 
                                                }}
                                                onClick={() => {
                                                    updateAvatar({
                                                    type: currentAssets + "Color",
                                                    id: index,
                                                    });
                                                }}
                                                ></div>
                                            );
                                            })
                                            : null}
                                        </div>

                                  </>
                                )
                              }
                            </div>
                          </div>
                          
                    )
                    }
                    {showLoader === "" && !showTemplates && (
                        <div style={{ position: 'absolute', top: '50%', left: '37%' }}>
                            <span className="loader-rpm"></span>
                        </div>
                    )}

                </div>

                {
                    !showTemplates && (<AssetPicker 
                                            handleAssetSelection={handleAssetSelection}
                                            assets={assets}
                                            avatarGender={avatarGender}
                                            updateAvatar={updateAvatar}
                                            equipTopOrBottom={equipTopOrBottom}
                                            currentAssets={currentAssets}
                                            handleGenderChange={handelGenderChange}
                                            close={close ? close : () => console.timeLog("clicked")}
                                            isModal={isModal}
                                            sendMessage={sendMessage}
                                            />)}
            </div>
            {showBtns && !showTemplates && (
                <div
                    style={{
                    position: 'relative',
                    top: -20,
                    left: 0,
                    width: '95%',
                    height: '95%',
                    backgroundColor: 'rgba(128, 128, 128, 0.5)',
                    pointerEvents: 'auto',
                    zIndex: 500, 
                    borderRadius: '15px'
                    }}
                />
                )}
   
            {isModal ? (
                <div style={{ position: 'absolute', bottom: '1%', right: '8%', zIndex: 5006436554365436 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label
                    htmlFor="avatarName"
                    style={{
                        color: 'black',
                        fontFamily: 'BackToSchool'
                    }}
                    >
                    Update Name:
                    </label>
                    <input
                    type="text"
                    className="custom-input-profile"
                    value={newName ? newName : ""}
                    onChange={e => handleAvatarName(e.target.value)}
                    onFocus={() => setShowBtns(true)}
                    // onBlur={() => setShowBtns(false)}
                    style={{
                        marginLeft: '15px',
                    }}
                    />
                    <button
                    className="close-button-rpm"
                    style={{ visibility: showBtns ? 'visible' : 'hidden' }}
                    onClick={() => {
                        console.log("Clicked")
                        updateNameOnly()}}
                    >
                    <img src="/images/buttons/check.png" alt="check" width={20}/>
                    </button>
                    <button
                    className="close-button-rpm"
                    style={{ visibility: showBtns ? 'visible' : 'hidden' }}
                    onClick={() => {
                        if (close) {
                        setNewName(player.displayName)
                        setShowBtns(false)
                        }
                    }}
                    >
                    <img src="/images/buttons/cross.png" alt="cross" width={20} style={{marginLeft: '5px'}}/>
                    </button>
                </div>
                </div>
            ) : null}
            <UserLogin open={openLogin} setOpen={setOpenLogin} setTemplates={setTemplates} />
        </div>
    );

}
import { Route, Routes} from 'react-router-dom';
import './App.css';
import React, { Fragment, useEffect} from "react";
import ReadyPlayerMeCreator from './components/ReadyPlayerMe/ReadyPlayerMeCreator';
import { InboundLinkRouting } from './components/InboundLinkRouting';
import { LandingPage } from './components/LandingPage/LandingPage';
import { ReadyPlayerMeCustom } from './components/ReadyPlayerMe/ReadPlayerMeCustom';
import { RPMWrapper } from './components/ReadyPlayerMe/RPMWrapper';


const App: React.FC = () => {

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
      } 
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  return (
      <Fragment>
            
                <Routes> 
                    <Route
                      path="/"
                      element={<Fragment>
                          <LandingPage/>
                        </Fragment>}
                    />
                    <Route
                      path="/mv/:id"
                      element={<Fragment>
                          <InboundLinkRouting/>
                        </Fragment>}
                    />
                    <Route
                      path="/mv"
                      element={<Fragment>
                          <InboundLinkRouting/>
                        </Fragment>}
                    />
                    <Route 
                      path="/mv/editor"
                      element={
                        <div style={{backgroundImage: 'url("/images/backgroundHero.png")', backgroundSize: 'cover', height: '100vh'}}>
                          <RPMWrapper/>
                        </div>
                      
                      }
                    />
                </Routes>
        </Fragment>
  );
}

export default App;
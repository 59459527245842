import { Divider } from "@mui/material"
import { AssetType, AvatarDetails } from "./RPMTypes"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { useNavigate } from "react-router-dom"
import { user } from "../../../store/appUser"

type Props = {
    handleAssetSelection: Function,
    assets: AvatarDetails[],
    updateAvatar: Function,
    equipTopOrBottom: Function,
    avatarGender: "male" | "female",
    currentAssets: string,
    handleGenderChange: Function,
    isModal: boolean,
    close:Function,
    sendMessage?: Function 
}

export const AssetPicker: React.FC<Props> = ({handleAssetSelection, assets, updateAvatar, equipTopOrBottom, avatarGender, currentAssets, handleGenderChange, isModal, close, sendMessage}) => {

    const history = useNavigate();
    const { player } = useAppSelector(state => state.appUser)
    const assetNames = [
        {name:"outfit", zoom: 3.2, image: "/images/svg/hanger-svgrepo-com.svg"},
        {name:"top", zoom: 3.2, image: "/images/svg/polo-shirt-svgrepo-com.svg"},
        {name:"bottom", zoom: 3.2, image: "/images/svg/jeans-4-svgrepo-com.svg"},
        {name:"headwear", zoom: 1, image: "/images/svg/hat-hat-svgrepo-com.svg"}, 
        {name:"footwear", zoom: 3.2, image: "/images/svg/shoes-7-svgrepo-com.svg"}, 
        {name:"beard", zoom:1, image: "/images/svg/mustache-svgrepo-com.svg"},
        {name:"eye", zoom:0.8, image: "/images/svg/eye-ball-svgrepo-com.svg"},
        {name:"eyebrows", zoom:0.8, image: "/images/svg/eyebrow-svgrepo-com.svg"}, 
        {name:"eyeshape", zoom:0.8, image: "/images/svg/eye-variant-with-enlarged-pupil-svgrepo-com.svg"}, 
        {name:"facemask", zoom:0.9, image: "/images/svg/makeup-brush-4-svgrepo-com.svg"}, 
        {name:"faceshape", zoom:0.9, image: "/images/svg/man-hair-head-svgrepo-com.svg"},
        {name:"facewear", zoom:0.9, image: "/images/svg/bully-2-svgrepo-com.svg"}, 
        {name:"glasses", zoom:0.8, image: "/images/svg/sunglasses-2-svgrepo-com.svg"},
        {name:"hair", zoom:1.5, image: "/images/svg/hairstyle-2-svgrepo-com.svg"},
        {name:"lipshape", zoom:0.8, image: "/images/svg/thin-lips-outline-svgrepo-com.svg"}, 
        {name:"noseshape", zoom:0.8, image: "/images/svg/nose-outline-svgrepo-com.svg"},
    ]

    function sendAvatarUrlToUnity(player: user){
        if(sendMessage){
            let data = {
                displayName: player.displayName,
                avatarUrl: player.glbUrl,
                avatarProfilePic: player.glbUrl?.replace(".glb", ".png"),
                colour: player.colour
              }
              sendMessage("ReactClientManager", "SetLocalAvatar",  JSON.stringify(data));
              close(false)
        }
    }

    return(
        <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
            backgroundColor: '#A9A29D',
            borderRadius: ' 0 15px 15px 0',
        }}>
            <h1 className="heading" style={{color: 'white', margin: '5px'}}>{currentAssets.charAt(0).toUpperCase() + currentAssets.slice(1)}</h1>
            <div style={{
                height: '50%',
                overflowY: 'auto'
            }}>
                
                {
                    assetNames.map(asset => {
                        return <button title={asset.name} className="asset-button" onClick={() =>handleAssetSelection(asset.name, asset.zoom)}><img height={30} alt="svg" src={asset.image}/></button>
                    })
                }
                <button className="asset-button" onClick={() => handleGenderChange("male")}>
                    <img
                    height={30}
                    alt="male"
                    title="male"
                    src="/images/svg/male-svgrepo-com.svg"
                    />
                </button>
                <button className="asset-button" onClick={() => handleGenderChange("female")}>
                    <img
                    height={30}
                    alt="female"
                    title="female"
                    src="/images/svg/female-svgrepo-com.svg"
                    />
                </button>
            </div>
            <div
                style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'nowrap'
                }}
            >
            <div>
                <button className="rpm-button" onClick={() => isModal ? sendAvatarUrlToUnity(player) :  history("/mv/demo")} >Accept</button></div>
            </div>
            <hr style={{ height: '1px', backgroundColor: 'gray', border: 'none', width: '100%', margin: 0 }} />
            <div style={{
                height: '50%',
                overflowY: 'auto'
            }}>
            {!["outfit", "top", "bottom","footwear", "eye", "eyeshape", "eyebrows", "faceshape","lipshape", "noseshape"].includes(currentAssets) ? <img
                src="/images/none.png" 
                id="custom-image"
                width={40}
                style={{ margin: '10px', cursor: 'pointer', transform: 'translateY(-5px)' }}
                onClick={() => {
                    updateAvatar({
                        id: "",
                        type: currentAssets
                    });
                }}
            /> : null}
            
            {assets.map(asset => (
                            asset.gender === avatarGender || asset.gender === "neutral" ? (
                                <img
                                    src={asset.iconUrl}
                                    id={asset.id}
                                    width={50}
                                    style={{ margin: '10px', cursor: 'pointer' }}
                                    onClick={() => {
                                        asset.type === "top" || asset.type === "bottom" ? equipTopOrBottom(asset) :
                                        updateAvatar(asset);
                                    }}
                                />
                            ) : null
                        ))}
            
            </div>
        </div>
    )
}
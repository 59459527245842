import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';

const TemplateSelector: React.FC<{
  templates: { id: string; imageUrl: string }[];
  loggedIntoRPM: boolean;
  showTemplates: boolean;
  templatesOrAvatars: boolean;
  assignTemplate: (template: any) => void;
  assignMyAvatar: (template: any) => void;
  getRandomAvatar: Function
}> = ({ templates, loggedIntoRPM, showTemplates, templatesOrAvatars, assignTemplate, assignMyAvatar, getRandomAvatar }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % templates.length);
  };

  const handlePrev = () => {
    setLoading(true);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + templates.length) % templates.length);
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  if (templates.length === 0 || !showTemplates) {
    return null;
  }

  const currentTemplate = templates[currentIndex];

  return (
<div
  style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '50%',
    margin: '2% auto',
    borderRadius: '25px',
    color: 'white',
    backgroundColor: 'rgb(193, 173, 144)',
    position: 'relative',
    overflow: 'visible',
  }}
>
  <div
    className="paw-container"
    style={{
      position: 'absolute',
      top: '-5%',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      gap: '10px',
    }}
  >
    <img height={20} src="/images/paw.png" alt="left paw" />
    <h1
      style={{
        margin: 0,
        fontSize: '1.3rem',
        padding: '5px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {loggedIntoRPM ? 'Select Your Avatar' : 'Select a Template'}
    </h1>
    <img height={20} src="/images/paw.png" alt="right paw" />
  </div>

  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgb(235, 235, 235)',
      margin: '50px 10px 10px 10px',
      width: '90%',
      height: '70%',
      padding: '15px',
      borderRadius: '50px',
    }}
  >
    <div
      style={{
        marginBottom: '20px', 
        textAlign: 'center',
        position: 'relative',
        width: '70%',
        height: '70%'
      }}
    >
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 7,
          }}
        >
          <CircularProgress color="error" />
          <span style={{ color: 'black' }}>Loading...</span>
        </div>
      )}
      <img
        src={`${currentTemplate.imageUrl}?size=256&quality=30`}
        alt="Avatar Template"
        onClick={() => {
          templatesOrAvatars
            ? assignTemplate(currentTemplate)
            : assignMyAvatar(currentTemplate);
        }}
        onLoad={handleImageLoad}
        style={{
          display: loading ? 'none' : 'block',
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    </div>

    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '20px', 
      }}
    >
      <button
        onClick={handlePrev}
        style={{
          background: 'none',
          border: '1px solid black',
          color: 'black',
          padding: '10px',
          cursor: 'pointer',
        }}
      >
        &lt; Prev
      </button>
      <button
        onClick={handleNext}
        style={{
          background: 'none',
          border: '1px solid black',
          color: 'black',
          padding: '10px',
          cursor: 'pointer',
        }}
      >
        Next &gt;
      </button>
    </div>
  </div>
  <div  style={{textAlign: 'center'}}>
                                <button style={{ margin : '20px'}} className="rpm-button" onClick={() => {
                                    assignTemplate(getRandomAvatar(templates, "male"))
                                }}>Random Male</button>
                                <button style={{ margin : '20px'}} className="rpm-button" onClick={() => {
                                    assignTemplate(getRandomAvatar(templates, "female"))
                                }}>Random Female</button>
                            </div>
</div>
  )
  
};

export default TemplateSelector;


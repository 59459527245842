import { useEffect, useRef, useState } from "react"
import { useAppSelector } from "../store/hooks"
import { CSSPropertiesWithVars } from "./LiveChat/chatWindowComponents/ChatBubble"
import { UpdateAvatarModal } from "./ReadyPlayerMe/UpdateAvatarModal"
import axios from "axios"

type Props = {
    sendMessage: Function
}

export const Profile: React.FC<Props> = ({sendMessage}) => {

    const { player, update } = useAppSelector(state  => state.appUser)
    const [open, setOpen] = useState(false)
    const playerRef = useRef(player)

    useEffect(() => {
        playerRef.current = player
    },[player])

    useEffect(() => {
        if(playerRef.current.glbUrl)
        loadImage(playerRef.current.glbUrl?.replace(".glb", ".png"))
    },[update])

    const loadImage = async (url: string) => {
        try {
          const response = await axios.get(url, {
            responseType: 'blob',
            headers: {
              'Cache-Control': 'no-cache'
            }
          });
      
          const imageUrl = URL.createObjectURL(response.data);
      
          const imgElement = document.getElementById("profile-image") as HTMLImageElement;
          if (imgElement) {
            imgElement.src = imageUrl;
          }

        } catch (error) {
          console.error('Error loading image:', error);
        }
    };

    return(
        <div>
            <button
                className="profile-button"
                onClick={() => {setOpen(!open)}}
            >
                <div className="image-container-online" style={
                {
                    '--gradient-color': "var(--primary-color)",
                    borderRadius: '50%', 
                    position: 'absolute', 
                    padding: '10px',
                    background: 'linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%)',
                    right: `16px`,
                    top: '16px',
                } as CSSPropertiesWithVars
            }>
            <div className="profile-inner-image-container profile">
                            <img 
                                className="profile-image"
                                id="profile-image"
                                alt=""
                            /> 
                            </div>
            
            </div>
            </button>
            <UpdateAvatarModal open={open} setOpen={setOpen} sendMessage={sendMessage}  />
        </div>
       
    )
}
export type Template = {
    imageUrl: string
    gender: string
    id: string
    usageType: string
}

export type AvatarDetails = {
    beardStyle: string;
    bodyType: "fullbody" | "halfbody" | string;
    campaignIds: string[];
    createdAt: string; 
    editable: boolean;
    eyeStyle: string;
    eyebrowStyle: string;
    faceBlendShapes: any[];
    gender: "male" | "female" | string; 
    glassesStyle: string;
    hairStyle: string;
    hasApps: boolean;
    iconGlow: boolean;
    iconUrl: string;
    id: string;
    isTemplate: boolean;
    locked: boolean;
    lockedCategories: string[];
    name: string;
    organizationId: string;
    removeSkin: boolean;
    type: "outfit" | "accessory" | "avatar" | string; 
    updatedAt: string; 
}

export type ApiAssetResponse = {
    data: AvatarDetails[]
}
  
export type ApiResponse = {
    data: {
        id: string;
        token: string;
    };
};
export type apiTemplateResponse = {
    data:[{
        imageUrl: string
        id: string
        gender: string
        usageType: string
    }]
}
export type assignedTemplateResponse = {
    data: {
        id: string
        partner: string
        gender: string
        bodyType: string
        assets: object
    }
}
export type LoginResponse = {
    data: {
        _id: string
        token: string
        refreshToken: string
    }
}
export type RefreshResponse = {
    token: string
    refreshToken: string
}

export type MyAvatarsResponse = {
    data: [{
        userId: string
        partner: string
        id: string
        data:{
            gender: string
        }
    }]
}

export type AssetType = {
    id: string | number
    type: string 
}

export const SkinColours = [
    'rgb(231, 178, 160)',
    'rgb(223, 170, 152)',
    'rgb(215, 162, 144)',
    'rgb(207, 154, 136)',
    'rgb(199, 146, 128)',
    'rgb(183, 130, 112)',
    'rgb(148, 105, 90)',
    'rgb(131, 93, 80)',
    'rgb(114, 81, 69)',
    'rgb(96, 68, 59)',
    'rgb(96, 68, 59)'
]

export const allColours = [
    'rgb(0, 0, 0)',
    'rgb(25, 25, 25',
    'rgb(73, 73, 73)',
    'rgb(75, 51, 41)',
    'rgb(88, 60, 47)',
    'rgb(118, 70, 57)',
    'rgb(145, 83, 52)',
    'rgb(105, 80, 56)',
    'rgb(128, 98, 60)',
    'rgb(147, 122, 99)',
    'rgb(173, 128, 87)',
    'rgb(187, 140, 75)',
    'rgb(199, 152, 96)',
    'rgb(216, 154, 112',
    'rgb(232, 175, 107)',
    'rgb(218, 181, 127)',
    'rgb(231, 187, 148)',
    'rgb(219, 201, 180)',
    'rgb(122, 105, 122)',
    'rgb(125, 114, 112)',
    'rgb(135, 129, 116)',
    'rgb(155, 143, 132)',
    'rgb(181, 161, 152)',
    'rgb(197, 183, 174)',
    'rgb(200, 200, 215)',
    'rgb(117, 54, 59',
    'rgb(154, 26, 26)',
    'rgb(161, 40, 49)',
    'rgb(189, 40, 22)',
    'rgb(228, 52, 48)',
    'rgb(154, 49, 10)',
    'rgb(158, 69, 36)',
    'rgb(208, 78, 46)',
    'rgb(225, 93, 35)',
    'rgb(192, 112, 49)',
    'rgb(191, 122, 68)',
    'rgb(236, 140, 66)',
    'rgb(233, 147, 27)',
    'rgb(224, 180, 43)',
    'rgb(233, 188, 42)',
    'rgb(225, 225, 86)',
    'rgb(154, 210, 84)',
    'rgb(132, 167, 57)',
    'rgb(115, 135, 71)',
    'rgb(131, 188, 127)',
    'rgb(104, 179, 116)',
    'rgb(60, 178, 127)',
    'rgb(95, 144, 126)',
    'rgb(42, 108, 84)',
    'rgb(83, 155, 155)',
    'rgb(78, 163, 195)',
    'rgb(77, 142, 188)',
    'rgb(71, 119, 169)',
    'rgb(96, 133, 219)',
    'rgb(100, 117, 182)',
    'rgb(92, 99, 159)',
    'rgb(102, 88, 119)',
    'rgb(136, 102, 135)',
    'rgb(167, 132, 177)',
    'rgb(173, 150, 216)',
    'rgb(190, 136, 188)',
    'rgb(224, 115, 219)',
    'rgb(227, 111, 144)',
    'rgb(231, 67, 110)',
    'rgb(214, 62, 101)',
    'rgb(179, 92, 141)',
    'rgb(149, 68, 114)'
]
import { ReadyPlayerMeCustom } from "./ReadPlayerMeCustom"


export const RPMWrapper:React.FC = () => {
    return(
        <div style={{ zIndex: 5 }}>
          <div  className='profile-modal' style={{ 
            position: 'absolute',
    
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70%'
                }}>
                <div className="paw-container-backpack" style={{transform: 'translateY(80px)', zIndex: 6000000}}>
                        <img height={20} src="/images/paw.png" alt="left paw" />
                        <h3 style={{margin: 0, fontSize: '25px'}}>Avatar Creation</h3>
                        <img height={20} src="/images/paw.png" alt="right paw" />
                </div>
                <ReadyPlayerMeCustom className='rpm-container' isModal={false} />
          </div>
    </div>
    )
}